<script setup>
  import { userStore } from "~/stores/user"
  import { accountStore } from "~/stores/account"

  const currentRoute = useRoute().name
  const uStore = userStore()
  const aStore = accountStore()
</script>

<template>
  <div class="flex justify-around my-4">
    <div class="w-1/3 flex items-center">
      <img src="/image/mythos/logo.png" alt="mythos-logo" class="h-10 mr-2" />
    </div>

    <div class="w-1/3 text-center">
      <h1 v-if="currentRoute === 'files' || currentRoute === 'folder'" class="text-[1.7rem]">
        <span class="font-light">Context</span> <span class="font-medium">Library</span>
      </h1>
      <h1 v-else class="text-[1.7rem]">
        <span class="font-light">Dashboard </span>
        <span v-if="currentRoute === 'index'" class="font-medium">Metrics</span>
        <span v-if="currentRoute === 'insights'" class="font-medium">Insights</span>
        <span v-if="currentRoute === 'moderation'" class="font-medium">Management</span>
        <span v-if="currentRoute === 'workflows'" class="font-medium">Workflows</span>
        <span v-if="currentRoute === 'workflows-create'" class="font-medium">Builder</span>
        <span v-if="currentRoute === 'settings'" class="font-medium">Settings</span>
      </h1>
    </div>

    <div class="w-1/3 flex justify-end">
      <div class="block text-sm text-right">
        <p>
          Hi,
          <span class="font-bold">{{
            uStore.user?.displayName || "welcome!"
          }}</span>
        </p>
        <p>{{ aStore.account?.businessName || "..." }}</p>
      </div>
      <div class="flex items-center text-center ml-6">
        <img
          class="w-9 h-9 border-2 border-violet-600 rounded-full object-cover"
          :src="uStore.user?.photoURL || '/user.png'"
          alt=""
        />
        <!-- <svg class="w-3 h-3 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg> -->
      </div>
    </div>
  </div>
</template>
